import api from "../httpClient.js";

export async function getPermissoes() {
  const response = await api.get("/permissoes");
  return response.data.permissoes;
}

export async function getPermissao(id) {
  const response = await api.get(`/permissoes/${id}`);
  return response.data.permissao;
}

export function putPermissao(id, permissao) {
  let body = {};
  for (let key in permissao) {
    body[key] = permissao[key];
  }
  return api.put(`/permissoes/${id}`, body);
}

export function postPermissao(permissao) {
  let body = {};
  for (let key in permissao) {
    body[key] = permissao[key];
  }
  return api.post("/permissoes/add", body);
}

export async function getUsuariosPermissoes() {
  const response = await api.get("/usuariospermissoes");
  return response.data.userpermissao;
}

export async function getUsuarioPermissoes(id) {
  const response = await api.get(`/usuarios/${id}/permissoes`);
  return response.data.user;
}

export async function putPermissaoUsuario(id, userPermissao) {
  return api.put(`/usuariospermissoes/${id}`, {
    nivel_acesso: userPermissao.nivel_acesso,
    valor: userPermissao.valor,
    operador: userPermissao.operador,
  });
}

export async function postPermissaoUsuario(userPermissao, userId, permissaoId) {
  return api.post("/usuariospermissoes/add", {
    permissao_id: permissaoId,
    user_id: userId,
    nivel_acesso: userPermissao.nivel_acesso,
    valor: userPermissao.valor,
    operador: userPermissao.operador,
  });
}
